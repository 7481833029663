import React, { useState } from "react"
import Layout from "../components/_App/layout"
import SEO from "../components/_App/seo"
import Navbar from "../components/_App/Navbar"
import Footer from "../components/_App/Footer"
import PageBanner from "../components/Common/PageBanner"
import { Link } from "gatsby"

export default function Profile() {
  const [userData, setUserData] = useState({
    icon:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2KJta8kWIZZuPXXKTB4LuhCDjh-yiecCt9BSBJ0WAAhFogOyBfZuTn8hlegwNRRFnShk&usqp=CAU",
    email: "johndoe@gmail.com",
    firstName: "John",
    lastName: "Doe",
    country: "United States",
    state: "CA",
    city: "Mountain View",
    schoolName: "GDSC",
    qwiklabsProfileLink: "qwiklabs.com/johndoe",
    googleDevProfileLink: "gdsc.com/johndoe",
    badges: {
      gdev: 10,
      qwikilabs: 2,
    },
  })
  return (
    <Layout>
      <SEO title="Profile" />

      <Navbar />

      <PageBanner pageTitle="Profile" />
      <div className="checkout-area ptb-80">
        <div className="container">
          <form>
            <div className={"row"}>
              <div className="text-center mb-5">
                <img
                  src={userData.icon}
                  alt=""
                  style={{
                    borderRadius: "50%",
                    border: "1px #9c9c9c solid",
                  }}
                />
              </div>{" "}
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="billing-details">
                  <h3 className="title">General Informations</h3>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>
                          First Name <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          defaultValue={userData.firstName}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>
                          Last Name <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          defaultValue={userData.lastName}
                        />
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label>School Name</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          defaultValue={userData.schoolName}
                        />
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-6">
                      <div className="form-group">
                        <label>
                          Town / City <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          defaultValue={userData.city}
                        />
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-6">
                      <div className="form-group">
                        <label>
                          State / Province <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          defaultValue={userData.state}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>
                          County <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          defaultValue={userData.country}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>
                          Email Address <span className="required">*</span>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          disabled
                          defaultValue={userData.email}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <div className="order-details">
                  <h3 className="title">Your Badges</h3>

                  <div className="order-table table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">Product Name</th>
                          <th scope="col">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.entries(userData.badges).map(entry => {
                          return (
                            <tr>
                              <td className="product-name">
                                <Link to="/livestream">{entry[0]}</Link>
                              </td>
                              <td className="product-total">
                                <span className="subtotal-amount">
                                  {entry[1]}
                                </span>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                  <Link to="#" className="btn btn-primary order-btn">
                    Modify Account Info
                  </Link>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <Footer />
    </Layout>
  )
}
